// Breakpoints
$bp-1: 576px;

//  Fonts
$primary-font: 'Open Sans', 'Helvetica Neue', sans-serif;
$monospaced-font: 'Lato', 'Courier New', monospace;

// Colors

// https://colors.eva.design/
$color-primary-1: #cce8ff;
$color-primary-2: #99ceff;
$color-primary-3: #66b0ff;
$color-primary-4: #3f94ff;
$color-primary-5: #0064fa;
$color-primary-6: #004fdb;
$color-primary-7: #003bb7;
$color-primary-8: #002993;
$color-primary-9: #001d7a;
$color-primary-transparent-1: rgba(0, 103, 255, 0.08);
$color-primary-transparent-2: rgba(0, 103, 255, 0.16);
$color-primary-transparent-3: rgba(0, 103, 255, 0.24);
$color-primary-transparent-4: rgba(0, 103, 255, 0.32);
$color-primary-transparent-5: rgba(0, 103, 255, 0.4);
$color-primary-transparent-6: rgba(0, 103, 255, 0.48);
$color-success-1: #eafcd4;
$color-success-2: #d1faa9;
$color-success-3: #adf17c;
$color-success-4: #8ae35a;
$color-success-5: #59d129;
$color-success-6: #3eb31d;
$color-success-7: #289614;
$color-success-8: #15790d;
$color-success-9: #096407;
$color-success-transparent-1: rgba(89, 209, 41, 0.08);
$color-success-transparent-2: rgba(89, 209, 41, 0.16);
$color-success-transparent-3: rgba(89, 209, 41, 0.24);
$color-success-transparent-4: rgba(89, 209, 41, 0.32);
$color-success-transparent-5: rgba(89, 209, 41, 0.4);
$color-success-transparent-6: rgba(89, 209, 41, 0.48);
$color-info-1: #daf6ff;
$color-info-2: #b5e9ff;
$color-info-3: #90d9ff;
$color-info-4: #75c8ff;
$color-info-5: #47acff;
$color-info-6: #3386db;
$color-info-7: #2364b7;
$color-info-8: #164693;
$color-info-9: #0d317a;
$color-info-transparent-1: rgba(71, 172, 255, 0.08);
$color-info-transparent-2: rgba(71, 172, 255, 0.16);
$color-info-transparent-3: rgba(71, 172, 255, 0.24);
$color-info-transparent-4: rgba(71, 172, 255, 0.32);
$color-info-transparent-5: rgba(71, 172, 255, 0.4);
$color-info-transparent-6: rgba(71, 172, 255, 0.48);
$color-warning: #dd742d;
$color-warning-0: #fff4e6;
$color-warning-1: #ffe8cc;
$color-warning-2: #ffd8a8;
$color-warning-3: #ffc078;
$color-warning-4: #ffa94d;
$color-warning-5: #ff922b;
$color-warning-6: #fd7e14;
$color-warning-7: #f76707;
$color-warning-8: #e8590c;
$color-warning-9: #d9480f;
$color-warning-transparent-1: rgba(255, 175, 56, 0.08);
$color-warning-transparent-2: rgba(255, 175, 56, 0.16);
$color-warning-transparent-3: rgba(255, 175, 56, 0.24);
$color-warning-transparent-4: rgba(255, 175, 56, 0.32);
$color-warning-transparent-5: rgba(255, 175, 56, 0.4);
$color-warning-transparent-6: rgba(255, 175, 56, 0.48);
$color-danger-1: #fee4d6;
$color-danger-2: #fec3ae;
$color-danger-3: #fe9a85;
$color-danger-4: #fd7467;
$color-danger-5: #fc3535;
$color-danger-6: #d82636;
$color-danger-7: #b51a35;
$color-danger-8: #921032;
$color-danger-9: #780a30;
$color-danger-transparent-1: rgba(252, 53, 53, 0.08);
$color-danger-transparent-2: rgba(252, 53, 53, 0.16);
$color-danger-transparent-3: rgba(252, 53, 53, 0.24);
$color-danger-transparent-4: rgba(252, 53, 53, 0.32);
$color-danger-transparent-5: rgba(252, 53, 53, 0.4);
$color-danger-transparent-6: rgba(252, 53, 53, 0.48);
$color-grey-0: #f9f9fb;
$color-grey-1: #F1F1F5;
$color-grey-2: #bdbec2;
$color-grey-3: #757575;
$color-grey-4: #5a5b5e;
$color-grey-5: #57575b;
$color-grey-6: #2e2f32;
$color-grey-7: #ddd;

$color-neon-green: #8bff32;
$color-neon-yellow: #fcff30;

$color-purple-5: #684bff;

$white: #fff;
$black: #000;

// Brand colors
$klarity-blue: $color-primary-5;
$klarity-navy: #42425c;

$klarity-grey: $color-grey-1;
$klarity-grey-dark: $color-grey-2;
$klarity-grey-darker: $color-grey-6;

// Stacking context
$z-1: 1;
$zFooter: 5000;
$zHeader: 6000;
$zDropdown: 7000;
$zOverlay: 8000;
$zModal: 9000;

// Border radius
$border-radius: 4px;

$laptop-mdpi-height: 800px;


$klarity-color-1: #404259;
$klarity-color-2: #9799A6;
$klarity-color-3: #2A678C;
$klarity-color-4: #18A2D9;
$klarity-color-5: #F2F2F2;
