.Region__bbox {
  border: 2px dotted transparent;
  opacity: 1;
  pointer-events: none;
  position: absolute;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out;
}

.Region__bbox--visible {
  background-color: rgba(0, 103, 255, 0.1);
  border-color: #0064fa;
}

.Region__icon {
  left: -32px;
  position: absolute;
  top: -2px;
  z-index: 3;
}

.Region__icon:focus-within,
.Region__icon:hover {
  z-index: 4;
}

/* Addition specificity required to override default button styles */
.Region .Region__icon button {
  background-color: #004fdb;
  border: 0.5px solid white;
  color: white;
  margin-right: 0;
  opacity: 0.5;
  pointer-events: auto;
  transition: opacity 150ms ease-in-out;
}

.Region .Region__icon button:focus,
.Region .Region__icon button:hover {
  opacity: 1;
}

.Region .Region__icon button svg {
  height: 16px;
  width: 16px;
}
