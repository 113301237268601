.baseInput {
  width: 100%;
  padding: 8px;
  font-family: $monospaced-font;
  font-size: 14px;
  background: transparent;
  border: 1px solid $color-grey-2;
  border-radius: $border-radius;
  outline: none;

  &::placeholder { color: $klarity-grey-dark; }
  &:focus { border: 1px solid $color-primary-5; }
}
